<template>
  <div id='teams'>
    <PageTitle text='協会への加盟申請の仕方' />
    <SectionTitle icon='mdi-email-newsletter' text='資格' />
    <p>
      日本アメリカンフットボール協会競技団体未登録のチーム
      <br />
      ただし、日本アメリカンフットボール協会普及団体登録チーム、団体については登録することが出来る
      <br />
      （例：大学同好会や地域協会登録チーム）
    </p>
    <SectionTitle icon='mdi-email-newsletter' text='特典' />
    <p>登録した団体、チーム、選手には以下の特典が与えられます。</p>
    <p>
      <ul>
        <li>協会企画運営の大会への優先出場権利</li>
        <li>協会企画運営の各種講習会への優先受講権利</li>
        <li>活動内容の広報誌への掲載</li>
        <li>活動内容の協会公式ＨＰでの紹介</li>
        <li>各種メディアへの活動状況の提供</li>
        <li>協会スポンサーよりの各種サポートの提供</li>
        <li>チーム活動に対する全国的なサポート</li>
        <li>その他各種サポート</li>
      </ul>
    </p>
    <SectionTitle icon='mdi-email-newsletter' text='申請' />
    <p>以下の書類を不備なく提出ください。</p>
    <p>
      <ul>
        <li>チーム名</li>
        <li>代表者住所、電話番号</li>
        <li>所属選手名簿</li>
        <li>昨シーズン活動内容（戦績）</li>
        <li>加盟希望理由（詳細に）</li>
      </ul>
    </p>
    <p>
      書類が不備なく提出された後、所属支部理事会にて審査されます。
      <br />
      必要な場合は、理事会に代表者を呼ぶ場合があります。
    </p>
    <SectionTitle icon='mdi-email-newsletter' text='加盟' />
    <p>
      申請が承認された場合、新規加盟関係書類を再度提出していただきます。
      <br />
      加盟後の活動については、所属支部により規定されています。
    </p>
    <SectionTitle icon='mdi-email-newsletter' text='提出先' />
    <p>
      すべての書類の提出先はこちら。
    </p>
    <p>
      〒253-0054
      <br />
      神奈川県茅ヶ崎市東海岸南6-3-22
      <br />
      茅ヶ崎東海岸ハイツ211
      <br />
      日本プライベートフットボール協会事務局
      <br />
      TEL/FAX：0467-82-1397
    </p>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import SectionTitle from '@/components/SectionTitle'

export default {
  components: { PageTitle, SectionTitle },
}
</script>
